export default {
  baseURL: 'https://api.atlantic-assur.com/v2/',
  // baseURL: 'http://45.79.252.67:3040/',
  primeAuto: 'primes/computes/auto',
  primeMoto: 'primes/computes/moto',
  primeMrh: 'primes/computes/mrh',
  primeVoyage: 'primes/computes/voyage',
  sign: 'registration/lite/check',
  confirmCode: 'registration/lite/confirm',
  souscriptions: 'managment/user/subscriptions',
  createSouscription: 'enroll',
  sinister: 'sinister/declarations',
  mySinister: 'sinister/declarations/me',
  saveImage: 'enroll/lite/send-image',
  saveAgence: 'registration/lite/agency',
  loginAgence: 'registration/lite/login-agency',
  login: 'auth/login',
  allClient: 'registration/all-clients',
  createSouscriptionAdmin: 'enroll/admin',
  saveDevis: 'registration/lite/save-estimate',
  getDevis: 'registration/lite/get-estimate',
  suspendContrat: 'enroll/lite/suspend',
  activateContrat: 'enroll/lite/restart',
  updatePlaque: 'enroll/lite/registration-update',
  renewAssurance: 'enroll/renew'
}
