import axios from 'axios'
import apiRoutes from '@/router/api-routes'
import store from '@/store'

const http = axios.create({
  baseURL: apiRoutes.baseURL,
  withCredentials: true
})

http.defaults.withCredentials = true
http.defaults.headers.common['x-api-key'] = process.env.VUE_APP_X_API_KEY
http.defaults.headers.common.Authorization = 'Bearer ' + store.getters.tokenState
http.interceptors.request.use(function (request) {
  if (!request.baseURL.indexOf(apiRoutes.baseURL)) {
  } else {
  }

  return request
}, function (error) {
  return Promise.reject(error)
})

http.interceptors.response.use(function (response) {
  return response
}, function (error) {
  return Promise.reject(error.response || { response: { status: error.statusCode, message: error.message } })
})

export default http
